import { Logo } from './Logo';
import { IntroText } from './IntroText';
import { Carousel } from './Carousel';

export function App() {
  return (
    <div className='container custom-container mt-4'>
      <div className='row'>
        <div className='col-md-auto mb-4'>
          <Logo />
        </div>
        <div className='col mb-4 rounded intro'>
          <IntroText />
        </div>
      </div>
      {/* /row */}
      <div className='row'>
        <div className='col mb-4 rounded intro'>
          <Carousel />
        </div>
      </div>
      {/* /row */}
    </div>
  );
}
